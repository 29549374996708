import React from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'

import ContactForm from '../components/contactform'
import Footer from '../components/footer'

export default (props) => (
  <Layout>
    <div id="wrapper">
      <header id="header" className="alt">
        {/* <span className="logo">
          <img src={mandala} alt="" style={{ width: `10em`, height: `10em` }} />
        </span> */}

        <h1>Rebecca English Counseling</h1>
      </header>

      <span className="image main">
        {/* <img src={cherryblossoms} alt="" /> */}

        <Img fluid={props.data.cherryblossoms.childImageSharp.fluid} />
        <h2>
          The journey of a thousand miles begins with a single step. -Chinese
          Proverb
        </h2>
      </span>

      <nav id="nav">
        <ul>
          <li>
            <a href="#intro" className="active">
              Introduction
            </a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#rates">Rates</a>
          </li>
          <li>
            <a href="#get-started">Get Started</a>
          </li>
        </ul>
      </nav>

      <div id="main">
        <section id="intro" className="main">
          <div className="spotlight">
            <div className="content">
              <header className="major">
                <h2>Welcome</h2>
              </header>
              <p>
                It has been a joy to work as a therapist for the past 15 years.
                I have been witness to much resilience, strength, healing and
                growth. My passion is working with children, adolescents and
                families, helping them to feel supported and validated as they
                increase peace in their lives, resolve issues and manage
                stressors and mental health symptoms. I have worked in the
                settings of residential mental health, outpatient mental health,
                psychiatric emergency department and as a school social worker.
              </p>

              <p>
                My office is located at 722 Weiland Road, Suite 207, Rochester,
                NY 14626. This is in the town of Greece, just two minutes from
                the State Route 390 Ridgeway exit. My business hours are weekday
                evenings and Saturdays. You can schedule your first appointment
                either by filling out the form below titled “get started”, by
                emailing me at{' '}
                <a href="mailto:renglishcounselingrochester@gmail.com">
                  renglishcounselingrochester@gmail.com
                </a>{' '}
                or by calling me at 585-484-0781.
              </p>

              <p>
                -Rebecca English, LCSW
                <br />
                New York State License 088823
              </p>

              {/* <ul className="actions">
                <li>
                  <a href="generic.html" className="button">
                    Learn More
                  </a>
                </li>
              </ul> */}
            </div>
            <span className="image">
              <Img fixed={props.data.becky.childImageSharp.fixed} />
            </span>
          </div>
        </section>

        <section id="services" className="main ">
          <header className="major">
            <h2>Services</h2>
          </header>
          <ul className="features">
            <li>
              <span className="icon major style1 fa-child" />
              <h3>Individual</h3>
              <p>
                Benefits of individual therapy are to feel better about
                yourself, think about relationships issues, feel more at peace,
                reduce stress, feel more connected with people in your life,
                work through problems, identify goals for living the kind of
                life you would like to live, managing mental health symptoms and
                learning healthy ways to cope. I use an integrated and holistic
                therapy approach that blends elements from different approaches
                and tailor treatment according to each client’s needs. Common
                theoretic approaches I pull from are cognitive behavioral
                therapy, dialectical behavioral therapy, narrative,
                solution-focused, person-centered, and parent skills training.
              </p>
            </li>
            <li>
              <span className="icon major style3 fa-home" />
              <h3>Family</h3>
              <p>
                The goal of family therapy is to increase positive
                communication, resolve family problems, navigate stressful
                events such as death, divorce, illness or child and adolescent
                issues, and to increase harmony in the home. Theoretic
                approaches I use most often in family therapy are strategic,
                narrative, solution- focused and reality therapy.
              </p>
            </li>
            <li>
              <span className="icon major style5 fa-users" />
              <h3>Group</h3>
              <p>
                I offer a healthy coping skills group for teens. This group uses
                Dialectical Behavioral Therapy curriculum to assist teens who
                struggle with regulating emotions, tolerating distress,
                interpersonal relationships, chronic suicidal thoughts or
                self-harming behaviors with learning healthier coping skills.
              </p>
            </li>
          </ul>

          <header className="major">
            <h2>Treatment For:</h2>
          </header>

          <h3>Anxiety</h3>

          <h3>Panic Attacks</h3>

          <h3>OCD</h3>

          <h3>Depression</h3>

          <h3>Trauma</h3>

          <h3>ADHD</h3>

          <h3>Behavior Issues</h3>

          <h3>Family Relationships</h3>

          <h3>Parenting</h3>
          <h3>LGBTQ support</h3>
        </section>

        <section id="rates" className="main">
          <header className="major">
            <h2>Rates</h2>

            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>55 minute session</td>
                  <td>$95</td>
                </tr>
                <tr>
                  <td>30 minute session</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>60 minute DBT skills group</td>
                  <td>$45</td>
                </tr>
              </tbody>
            </table>

            <h2>Payment Options</h2>
            <p>
              Cash, check, all major credit cards, health savings and flex
              spending account cards.
            </p>
            <p>
              *Please note that I do not bill insurance companies to protect
              your privacy but your insurance company can still reimburse you
              for the cost of your therapy session. I can provide you with a
              “super bill” at the end of each session that includes a diagnosis
              code, date and length of session that you can submit to your
              insurance company for reimbursement. Please contact your insurance
              company to see if they will reimburse for “Out of Network”
              providers.
            </p>
            <h2>Cancellation Policy:</h2>
            <p>
              Please notify me 24 hours in advance if you need to cancel or
              reschedule your appointment. You will be required to pay the full
              cost of your session if you miss the scheduled appointment without
              24-hour notice (pending medical emergencies or severe weather).
            </p>
          </header>
        </section>

        <ContactForm />
      </div>

      <Footer />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    cherryblossoms: file(relativePath: { eq: "cherryblossoms.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    becky: file(relativePath: { eq: "becky.jpg" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
