import React from 'react'

import { navigate } from 'gatsby-link'

import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = `6LdksOYUAAAAAN4VXmBRD2aDF39RKDGC5vZ5IYkC`

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function ContactForm() {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    if (recaptchaValue === '') {
      alert(
        'Form cannot be submitted. Please confirm that you are not a robot.'
      )
      return
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <section id="get-started" className="main">
      <header className="major">
        <h2>Get Started</h2>
        <p>
          With a little info about yourself we can get started and schedule your
          first session.
        </p>
      </header>

      <form
        onSubmit={handleSubmit}
        method="post"
        name="intake"
        data-netlify="true"
        data-netlify-recaptcha="true"
        action="/success/"
      >
        <input type="hidden" name="form-name" value="intake" />
        <div className="row gtr-uniform">
          <div className="col-3 col-12-xsmall">
            <label htmlFor="clientName">Client Name</label>
            <input
              type="text"
              name="clientName"
              id="clientName"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="clientDob">DOB</label>
            <input
              type="text"
              name="clientDob"
              id="clientDob"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="preferredGender">Preferred Gender</label>
            <input
              type="text"
              name="preferredGender"
              id="preferredGender"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="clientPhoneNumber">Client Phone Number</label>
            <input
              type="text"
              name="clientPhoneNumber"
              id="clientPhoneNumber"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="clientAddress">Client Address</label>
            <input
              type="text"
              name="clientAddress"
              id="clientAddress"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="clientEmail">Client Email</label>
            <input
              type="text"
              name="clientEmail"
              id="clientEmail"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-2 col-12-xsmall">
            <h3>If under 18:</h3>
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="parentOrGuardianName">
              Parent/Legal Guardian Name
            </label>
            <input
              type="text"
              name="parentOrGuardianName"
              id="parentOrGuardianName"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="parentOrGuardianPhone">Parent Phone Number</label>
            <input
              type="text"
              name="parentOrGuardianPhone"
              id="parentOrGuardianPhone"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-4 col-12-xsmall">
            <label htmlFor="parentOrGuardianEmail">Parent Email</label>
            <input
              type="email"
              name="parentOrGuardianEmail"
              id="parentOrGuardianEmail"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-2 col-12-xsmall">
            <h3>If over 18:</h3>
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="emergencyContactName">Emergency Contact Name</label>
            <input
              type="text"
              name="emergencyContactName"
              id="emergencyContactName"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="emergencyContactNumbebr">
              Emergency Phone Number
            </label>
            <input
              type="text"
              name="emergencyContactNumbebr"
              id="emergencyContactNumbebr"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-4 col-12-xsmall">
            <label htmlFor="emergencyContactEmail">Emergency Email</label>
            <input
              type="email"
              name="emergencyContactEmail"
              id="emergencyContactEmail"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12 col-12-xsmall">
            <label htmlFor="referredBy">Referred By</label>
            <input
              type="text"
              name="referredBy"
              id="referredBy"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-2 col-12-xsmall">Primary Care Physician</div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="primaryPhyscianName">Physician Name</label>
            <input
              type="text"
              name="primaryPhyscianName"
              id="primaryPhyscianName"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-3 col-12-xsmall">
            <label htmlFor="primaryPhysicianPhone">
              Physician Phone Number
            </label>
            <input
              type="text"
              name="primaryPhysicianPhone"
              id="primaryPhysicianPhone"
              defaultValue=""
              onChange={handleChange}
            />
          </div>
          <div className="col-4 col-12-xsmall">
            <label htmlFor="primaryPhysicianAddress">Physician Address</label>
            <input
              type="text"
              name="primaryPhysicianAddress"
              id="primaryPhysicianAddress"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <label htmlFor="previousTherapists">
              List any previous therapists, psychologists, or psychiatrists you
              have attended treatment with
            </label>
            <input
              type="text"
              name="previousTherapists"
              id="previousTherapists"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <label htmlFor="inpatientPsychiatricHospitalizations">
              If you have any history of inpatient psychiatric hospitalizations,
              please list name of hospital and date of admission
            </label>
            <input
              type="text"
              name="inpatientPsychiatricHospitalizations"
              id="inpatientPsychiatricHospitalizations"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <label htmlFor="recentSelfHarming">
              If you have any history of suicide attempts or self-harming, when
              was the most recent?
            </label>
            <input
              type="text"
              name="recentSelfHarming"
              id="recentSelfHarming"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <label htmlFor="currentPrescriptions">
              List any current prescription medications
            </label>
            <input
              type="text"
              name="currentPrescriptions"
              id="currentPrescriptions"
              defaultValue=""
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <label htmlFor="whatBringsYouToTherapy">
              Describe what brings you to therapy at this time and what would
              you like to get out of treatment
            </label>
            <textarea
              name="whatBringsYouToTherapy"
              id="whatBringsYouToTherapy"
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <Recaptcha sitekey={RECAPTCHA_KEY} ref={recaptchaRef} />
          </div>
          <div className="col-12">
            <input
              type="submit"
              value="Submit"
              className="primary"
              style={{ fontWeight: `bold` }}
            />
          </div>
        </div>
      </form>
    </section>
  )
}
