import React from 'react'

export default function Footer() {
  return (
    <footer id="footer">
      <section>
        <h2>Forms</h2>
        <ul>
          <li>
            <a href="http://rebeccaenglishcounseling.com.s3.amazonaws.com/Health%20Insurance%20Portability%20and%20Accountability%20Act.docx">
              HIPAA Disclosure
            </a>
          </li>
          <li>
            <a href="http://rebeccaenglishcounseling.com.s3.amazonaws.com/Consent%20to%20Release%20Information.docx">
              Consent to Release Information
            </a>
          </li>
          <li>
            <a href="http://rebeccaenglishcounseling.com.s3.amazonaws.com/Informed%20Consent%20for%20Adults.docx">
              Informed Consent for Adults
            </a>
          </li>
          <li>
            <a href="http://rebeccaenglishcounseling.com.s3.amazonaws.com/Informed%20Consent%20for%20Minors.docx">
              Informed Consent for Minors
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2>Contact</h2>
        <dl className="alt">
          <dt>Address</dt>
          <dd>
            722 Weiland Road Suite 207
            <br /> Rochester, NY 14626 <br /> USA
          </dd>
          <dt>Phone</dt>
          <dd>585-484-0781</dd>
          <dt>Email</dt>
          <dd>
            <a href="mailto:renglishcounselingrochester@gmail.com">
              renglishcounselingrochester@gmail.com
            </a>
          </dd>
        </dl>
      </section>
      <p className="copyright">&copy; Rebecca English Counseling.</p>
    </footer>
  )
}
